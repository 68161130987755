
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import VideoPlayer from "../Component/VideoPlayer";

import Layout from "../Component/Layout";
import "./Aboutus.css";

const AboutUs = () => {
  const [aboutData, setAboutData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch data from the API
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/aboutus`);
        if (response.data.success) {
          setAboutData(response.data.data);
        } else {
          setError("Failed to fetch data.");
        }
      } catch (err) {
        console.error("Error fetching About Us data:", err);
        setError("An error occurred while fetching data.");
      }
    };

    fetchAboutData();
  }, []);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <Layout>
      <div className="position-relative text-center mb-5 aboutUsPageBanner" id="topimg">
        {aboutData[0]?.AboutBanner && (
          <img
            className="curved-image"
            src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${aboutData[0].AboutBanner}`}
            alt="About Banner"
          />
        )}
      </div>

      <div className="my-3 py-2">
        <VideoPlayer />
      </div>

      <div className="section-title">
        <div className="line"></div>
        <h2>International Teaching Faculty Speak About Us</h2>
        <div className="line"></div>
      </div>

      <Container className="mt-5">
        <Row>
          {aboutData.map((person, index) => (
            <Col md={4} className="mb-4" key={index}>
              <div className="card h-100">
                <div className="img-box mx-auto" style={{ height: '240px', overflow: 'hidden' }}>
                  <img
                    className="img-fluid"
                    src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${person.image}`}
                    alt={person.title}
                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  />
                </div>
                <div className="card-body text-center d-flex flex-column">
                  <h5 className="card-title">{person.Pagetitle}</h5>
                  <h6 className="card-title">{person.title}</h6>
                  <p className="card-text text-blue">{person.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>


    </Layout>
  );
};

export default AboutUs;
