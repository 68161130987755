import React from 'react';
import Layout from '../Component/Layout';
import Main from "../Component/MainContent"
import Explore from "../Component/Explore"
import Activites from "../Component/Activities"
const Home = () => {
  return (
    <>
      <Layout>
        <Main />

        <Explore />
        <Activites />
      </Layout>
    </>
  );
};

export default Home;
