
import React from "react";
import "./Footer.css";
import logo from '../Gallery/logowhite.png';
import { Link } from "react-router-dom";

const Footer = ({ footer }) => {
  return (
    <footer className="footer text-white">
      <div className="container-fluid py-2">
        <div className="row">
          {/* Logo and Social Media Section */}
          <div
            className="col-md-4 d-flex align-items-center justify-content-center flex-column"
            style={{ padding: "20px" }}
          >
            <div className="footer-logo">
              {/* <img
                src={`${process.env.REACT_APP_BASE_URL_IMAGE}/${footer?.logo}`}
                alt="School Logo"
                className="img-fluid"
                style={{ borderRadius: "10px" }}
              /> */}
                            <img
                src={logo}
                alt="School Logo"
                className="img-fluid"
                style={{ borderRadius: "10px" }}
              />
            </div>
            <div className="my-3">
              <div className="social-icons">
                {footer?.facebook && (
                  <a href={footer.facebook} target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-facebook p-2 text-white"></i>
                  </a>
                )}
                {footer?.twitter && (
                  <a href={footer.twitter} target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-twitter p-2 text-white"></i>
                  </a>
                )}
                {footer?.instagram && (
                  <a href={footer.instagram} target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-instagram p-2 text-white"></i>
                  </a>
                )}
                {footer?.linkedin && (
                  <a href={footer.linkedin} target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-linkedin p-2 text-white"></i>
                  </a>
                )}
                {footer?.youtube && (
                  <a href={footer.youtube} target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-youtube p-2 text-white"></i>
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Useful Links and Contact Section */}
          <div className="col-md-8">
            <div className="container">
              <div className="row justify-content-end">
                {/* Useful Links */}
                <div className="col-md-5 px-3">
                  <div className="mx-auto">
                    <h4>Useful Links</h4>
                    <ul>
                      <li className="p-2">
                        <Link to="/Aboutus" className="text-white">
                          About School
                        </Link>
                      </li>
                      <li className="p-2">
                        <Link to="/Activities" className="text-white">
                          School Activities
                        </Link>
                      </li>
                      <li className="p-2">
                        <Link to="/RegistrationForm" className="text-white">
                          Online Admission
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Contact Us */}
                <div className="col-md-5 px-3">
                  <div>
                    <h4>Contact Us</h4>
                    <p style={{ lineHeight: "2rem" }}>
                      Address: {footer?.address || "N/A"}
                    </p>
                    <p>Phone No: <a href={`tel:${footer?.contact}`}> {footer?.contact || "N/A"} </a></p>
                    <p>Email: <a href="mailto:mdbritishfort@gmail.com">mdbritishfort@gmail.com</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>

        {/* Footer Bottom */}
        <div className="row text-center">
          <div className="col-md-12">
            <p className="text-white">
              Powered by -{" "}
              <a
                href="https://crimsonssystems.com/"
                className="text-white text-decoration-none"
                target="_blank"
                rel="noopener noreferrer"
              >
                Crimsons Systems Jabalpur
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
