
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import Layout from '../Component/Layout';
import img1 from '../Gallery/AYUSH PATEL.jpg';
import img2 from '../Gallery/Arish Goutam.jpg';
import img3 from '../Gallery/JAI DEV SINGH.jpg';
import img4 from '../Gallery/VAIBHAV KOTHARI.jpg';
import img5 from '../Gallery/misthi gupta.jpg';

const RegistrationForm = () => {
  const initialFormData = {
    name: '',
    dob: '',
    gender: '',
    email: '',
    motherName: '',
    motherPhone: '',
    fatherName: '',
    fatherPhone: '',
    address: '',
    illness: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false); // Loading state for submission
  const [message, setMessage] = useState(''); // Success/error message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(''); // Clear previous messages

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/online-registrations`, formData);
      console.log('Form submitted successfully:', response.data);
      setMessage('Form submitted successfully!');
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('Error submitting form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const reviewStudent = [{
    name: 'Ayush Patel',
    place: 'Mumbai',
    description: 'Here in British Fort Foundation we have actually realised why school life is called the gloden period of life.',
    image: img1,
  },

  {
    name: 'Misthi Gupta',
    place: 'Delhi',
    description: 'My School has the biggest lab section with Computer Lab, Robotics lab, ATAL Tinkering lab, Japanese Maths lab and a lot more.',
    image: img5,
  },

  {
    name: 'Jaidev Singh',
    place: 'Haridwar',
    description: 'Table Tennis, Skating, Basketball, Athletics, Swimming, Snooker Shooting & Archery and a lot more our school provides best sport facilities.',
    image: img3,
  },

  {
    name: 'Vaibhav Kothari',
    place: 'Uttarakhand',
    description: 'My School British Fort Foundation has the most advanced smart classes and 3D labs with wi-fi connections for best conceptual studies.',
    image: img4,
  },

  {
    name: 'Arish Gautam',
    place: 'Bihar',
    description: 'Our director sir Mr. Anurag Soni never miss chance to boost us with energy to pay more attention on our studies and become winner in life.',
    image: img2,
  },
  ];

  return (
    <Layout>
      {/* <div className="position-relative text-center mb-5" id="topimg">
        <img className="curved-image" src="" alt="Registration Banner" />
      </div> */}
      <div className="section-title">
        <div className="line"></div>
        <h2>School Registration Form</h2>
        <div className="line"></div>
      </div>
      <div style={{ backgroundColor: '#f0f0f0', minHeight: '80vh' }}>
        <Container>
          
          <Form className="registration-form py-3" onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="name">
                  <Form.Label>Name *</Form.Label>
                  <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="dob">
                  <Form.Label>Date of Birth *</Form.Label>
                  <Form.Control type="date" name="dob" value={formData.dob} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="gender">
                  <Form.Label>Gender *</Form.Label>
                  <Form.Control as="select" name="gender" value={formData.gender} onChange={handleChange} required>
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email Address *</Form.Label>
                  <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="motherName">
                  <Form.Label>Mother's Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherName"
                    value={formData.motherName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="motherPhone">
                  <Form.Label>Mother's Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="motherPhone"
                    value={formData.motherPhone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="fatherName">
                  <Form.Label>Father's Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherName"
                    value={formData.fatherName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="fatherPhone">
                  <Form.Label>Father's Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherPhone"
                    value={formData.fatherPhone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="address">
                  <Form.Label>Home Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="illness">
                  <Form.Label>Does your child suffer from any illness or disability?</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="Yes"
                      name="illness"
                      value="yes"
                      checked={formData.illness === 'yes'}
                      onChange={handleChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="No"
                      name="illness"
                      value="no"
                      checked={formData.illness === 'no'}
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit" className="my-4" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </Form>
          {message && <p className="my-3 text-center text-success">{message}</p>}
        </Container>
      </div>

      <div>
        <div className="section-title">
          <div className="line"></div>
          <h2>Our Student's Review</h2>
          <div className="line"></div>
        </div>

        <div className="container mt-5">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {reviewStudent.map((item, index) => (
              <div className="col" key={index}>
                <div className="card h-100 d-flex flex-column">
                  {/* Image Wrapper with Fixed Height */}
                  <div className="studentImg" style={{ overflow: 'hidden' }}>
                    <img
                      src={item.image}
                      className="card-image card-img-top img-fluid"
                      alt={item.name}
                      style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                    />
                  </div>

                  {/* Card Body with Flex for Uniform Height */}
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{item.name}</h5>
                    <h6 className="card-title">{item.place}</h6>
                    <p className="card-text flex-grow-1">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>


      </div>
    </Layout>
  );
};

export default RegistrationForm;
