
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import './activity.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfrastructureSlider = () => {
  const [schoolNews, setSchoolNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch school news data
    axios.get(`${process.env.REACT_APP_BASE_URL}/schoolnews`)
      .then(response => {
        console.log('School news response:', response.data);
        setSchoolNews(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching school news:', error.response || error.message);
        setLoading(false);
      });
  }, []);

  // Check if schoolNews is populated
  console.log("schoolNews state:", schoolNews);

  if (loading) {
    return <div>Loading...</div>;
  }

  const settings = {
    dots: true, // Show navigation dots
    infinite: schoolNews.length > 1, // Enable infinite scroll only if there are multiple images
    speed: 500, // Transition speed
    slidesToShow: 2, // Number of slides visible at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: schoolNews.length > 1, // Enable autoplay only if there are multiple images
    autoplaySpeed: 3000, // Time between slides
    responsive: [
      {
        breakpoint: 1024, // For medium screens
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 1, // Show 1 slide on smaller screens
        }
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className="position-relative">
      <div className="container schoolnews py-2">
        <div className="row text-center schoolnewshead">
          <h3>SCHOOL NEWS</h3>
        </div>
        <div className="schoolnewsBox py-3">
          {schoolNews && schoolNews.length > 1 ? (
            <Slider {...settings}>
              {schoolNews.map((news, index) => (
                <div key={index} className="slider-item">
                  <div className="box">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${news.image}`}
                      alt="news"
                      className="img-fluid"
                    />
                    <h3 className="text-center">{news.description || "No Title"}</h3>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            schoolNews.map((news, index) => (
              <div key={index} className="box text-center">
                <img
                  src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${news.image}`}
                  alt="news"
                  className="img-fluid"
                />
                <h3 className="text-center">{news.description || "No Title"}</h3>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default InfrastructureSlider;
