
import React, { useEffect, useState } from "react";

const VideoPlayer = () => {
    const [bannerData, setBannerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const storedBanner = localStorage.getItem('bannerData');
        console.log("video data check here", storedBanner);
        if (storedBanner) {
            try {
                const banners = JSON.parse(storedBanner);

                // Check if there are enough banners and if index 1 exists
                if (banners.length > 1) {
                    setBannerData(banners[1]);  // Set the second banner (index 1)
                } else {
                    setError("Second banner not available.");
                }
            } catch (e) {
                setError("Failed to parse banner data.");
                console.error("Error parsing banner data", e);
            }
        } else {
            setError("No banner data found in localStorage.");
        }
        setLoading(false);
    }, []);

    if (loading) return <p>Loading Banner...</p>;

    if (error) return <p>{error}</p>; // Display error message if data is missing

    if (!bannerData) {
        return <p>No second banner available.</p>; // Fallback if no banner data exists
    }

    return (
        <div className="video-containers" style={{ width: '100%', height: '75vh' }}>
            <video
                controls
                controlsList="nodownload noremoteplayback"
                style={{ height: '100%', width: '100%', boxSizing: 'border-box' }}>
                <source
                    src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${bannerData.video}`}
                    type="video/mp4"
                />
            </video>
        </div>
    );
};

export default VideoPlayer;
