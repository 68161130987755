import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Component/Navbar';
import Header from './Component/Header';
import Facilities from './Pages/Facilities';
import Activities from './Pages/Activities';
import Homepage from './Pages/Homepage';
import Aboutus from './Pages/Aboutus';
import ContactUs from './Pages/Contactus';
import RegistrationForm from './Pages/Registation';
import EnrollmentSearch from './Pages/EnrollmentSearch';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>

          <Route path="" element={<Homepage />} />
          <Route path="/Activities" element={<Activities />} />
          <Route path="/navbar" element={<Navbar />} />
          <Route path='/Aboutus' element={<Aboutus />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/Facilities' element={<Facilities />} />
          <Route path='/RegistrationForm' element={<RegistrationForm />} />
          <Route path='/Header' element={<Header />} />
          <Route path='/enrollmentSearch' element={<EnrollmentSearch />} /> 


          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );

}

export default App;
