import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from './Header'
import Footer from './Footer'
import Navbar from "./Navbar";

const baseurl = process.env.REACT_APP_BASE_URL;

const Layout = (props) => {
    const [footer, setFooter] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchFooterData = async () => {
        try {
          const response = await axios.get(`${baseurl}/settings`);
          if (response.data && response.data.length > 0) {
            setFooter(response.data[0]);
          } else {
            throw new Error("Footer data is empty");
          }
        } catch (err) {
          setError(err.message || "Failed to fetch footer data.");
        } finally {
          setLoading(false);
        }
      };
  
      fetchFooterData();
    }, []);

  return (
    <div>
      
      <Navbar />
      <main>
      {props.children}
      </main>
      <Footer footer={footer} />
      
    </div>
  )
}

export default Layout