import React, { useState } from 'react';
import axios from 'axios';
import { Document, Page } from 'react-pdf';
import Layout from '../Component/Layout';

// import './Activity.css';

const EnrollmentSearch = () => {
    const [studentName, setStudentName] = useState('');
    const [rollNo, setRollNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setPdfUrl(null);

        try {
            // Fetch Transfer Certificate for the given studentName and rollNo
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/getTC/${studentName}/${rollNo}`
            );

            if (response.data.success) {
                // Construct the full PDF URL from the file name
                const pdfUrl = `${process.env.REACT_APP_BASE_URL_IMAGE}/${response.data.data.tcFile}`;
                setPdfUrl(pdfUrl);
            } else {
                setError(response.data.message || 'No data found.');
            }
        } catch (err) {
            setError('Failed to fetch Transfer Certificate.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>

            <div className="container mt-5">
                <h2 className="text-center mb-4">Search Transfer Certificate</h2>
                <form onSubmit={handleSearch} className="text-center">
                    <div className="form-group mb-3">
                        <label htmlFor="studentName" className="form-label">
                            Enter Student Name:
                        </label>
                        <input
                            type="text"
                            id="studentName"
                            className="form-control"
                            placeholder="Enter Student Name"
                            value={studentName}
                            onChange={(e) => setStudentName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="rollNo" className="form-label">
                            Enter Roll Number:
                        </label>
                        <input
                            type="text"
                            id="rollNo"
                            className="form-control"
                            placeholder="Enter Roll Number"
                            value={rollNo}
                            onChange={(e) => setRollNo(e.target.value)}
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Searching...' : 'Search'}
                    </button>
                </form>

                {/* Error Message */}
                {error && <div className="alert alert-danger mt-3">{error}</div>}

                {/* Display PDF */}
                {pdfUrl && (
                    <div className="mt-5">
                        <h3>Transfer Certificate PDF</h3>
                        <Document file={pdfUrl}>
                            <Page pageNumber={1} />
                        </Document>
                    </div>
                )}
            </div>
        </Layout>

    );
};

export default EnrollmentSearch;
