
import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import "./Header.css";
import hos1 from '../Gallery/hostel1.jpg';
import hos2 from '../Gallery/hostel2.jpg';

const baseurl = process.env.REACT_APP_BASE_URL;

const Header = () => {

  const [footer, setFooter] = useState({});
  const [videoData, setVideoData] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videoError, setVideoError] = useState(null);
  const [announcementError, setAnnouncementError] = useState(null);
  const [footerError, setFooterError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Ref for video
  const videoRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetching all data concurrently
        const [footerResponse, videoResponse, announcementResponse] = await Promise.all([
          axios.get(`${baseurl}/settings`),
          axios.get(`${process.env.REACT_APP_BASE_URL}/banner`),
          axios.get(`${process.env.REACT_APP_BASE_URL}/getAllAnnouncement`),
        ]);

        // Handling Footer Data
        if (footerResponse.data && footerResponse.data.length > 0) {
          setFooter(footerResponse.data[0]);
        } else {
          throw new Error("Footer data is empty");
        }

        // Handling Video Data
        if (videoResponse.data.length > 0) {
          setVideoData(videoResponse.data[0]);
          localStorage.setItem('bannerData', JSON.stringify(videoResponse.data));

        } else {
          setVideoError("No video data found.");
        }

        // Handling Announcement Data
        if (announcementResponse.data?.data?.length > 0) {
          setAnnouncements(announcementResponse.data.data);
        } else {
          setAnnouncementError("No announcements found.");
        }

      } catch (err) {
        setFooterError(err.message || "Failed to fetch footer data.");
        setVideoError("Failed to load video.");
        setAnnouncementError("Failed to load announcements.");
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, []);

  if (loading) return <div className="d-flex justify-content-center align-items-center">  <p className="text-white">Loading data...</p> </div>;

  return (
    <>

      <div className="d-flex justify-content-between align-items-center w-100 headWrapper">
        <div className="flex-grow-1">
          {announcements.length > 0 ? (
            <marquee behavior="scroll" direction="left" scrollamount="5">
              {announcements.map((announcement, index) => (
                <span key={index} className="marquee-item">
                  {announcement.title}
                </span>
              ))}
            </marquee>
          ) : (
            <p className="text-danger text-center">{announcementError}</p>
          )}
        </div>
        <div className="headerSocial mx-3">
          {footer?.facebook && (
            <abbr title="facebook"> 
            <a href={footer.facebook} target="_blank" rel="noopener noreferrer">
              <i className="bi bi-facebook p-1 my-2 text-white"></i>
            </a>  </abbr>
          )}
          {footer?.instagram && (
            <abbr title="Instagram">  <a href={footer.instagram} target="_blank" rel="noopener noreferrer">
              <i className="bi bi-instagram p-1 my-2 text-white"></i>
            </a> </abbr> 
          )}

          <div onClick={() => setShowModal(true)} className="d-inline-block">
            <abbr title="Hostel Facility">
              <i className="bi bi-houses text-white"></i>
            </abbr>
          </div>

        </div>
      </div>

      <>
        {showModal && (
          <div className="modal fade show d-block" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Hostel Facility</h5>
                  <button
                    type="button btn-light"
                    className="btn-close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="modalImage">
                      <img src={hos1} alt="image" loading="lazy" />
                    </div>
                    <div className="modalImage">
                      <img src={hos2} alt="image" loading="lazy" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showModal && <div className="modal-backdrop fade show"></div>}
      </>



      <div className="video-container" style={{ width: "100%", height: "70vh" }}>
        <video
          ref={videoRef}
          className="video"
          controls
          controlsList="nodownload"
          style={{ height: "100%", width: "100%", boxSizing: "border-box" }}
          onError={() => setVideoError("Video failed to load.")}
        >
          {videoData && (
            <source
              src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${videoData.video}`}
              type="video/mp4"
            />
          )}
        </video>
        {videoError && <p className="text-danger text-center mt-3">{videoError}</p>}
      </div>
    </>
  );
};

export default Header;
