
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Layout from '../Component/Layout';
import './Activities.css';

const Activities = () => {
  // States to store the banner and activities data
  const [activities, setActivities] = useState([]);
  const [banner, setBanner] = useState('');

  // Fetch data from the API on component mount
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/activities`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setActivities(data.data);
          setBanner(data.data[0]?.ActivitiesBanner);
        }
      })
      .catch(err => console.error("Error fetching data:", err));
  }, []);

  return (
    <Layout>
      <div className="position-relative text-center mb-5" id="topimg">
        {/* Dynamically set the banner image */}
        <img className="curved-image" src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${banner}`} alt="" />
      </div>
      <div className="section-title">
        <div className="line"></div>
        <h2>Sports Infrastructure</h2>
        <div className="line"></div>
      </div>
      <Row className='mt-4 mb-4'>
      </Row>

      <Container className="mt-5">
        <Row>
          {activities.map((activity) => (
            <Col md={4} className="mb-4" key={activity.id}>
              <Card className="activity-card h-100 d-flex flex-column">
                {/* Image Wrapper for Fixed Height */}
                <div className="img-container" style={{ height: '200px', overflow: 'hidden' }}>
                  <Card.Img
                    variant="top"
                    src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${activity.image}`}
                    className="card-img"
                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  />
                </div>

                {/* Card Body with Flex for Equal Height */}
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{activity.title}</Card.Title>
                  <Card.Text className="flex-grow-1">
                    {activity.description || 'No description available for this activity.'}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>


    </Layout>
  );
}

export default Activities;
