
import React, { useState } from "react";
import axios from "axios";
import "./ContactUs.css";
import Layout from "../Component/Layout";

const baseurl = process.env.REACT_APP_BASE_URL;

const ContactUs = () => {
    // State for form data
    const [formData, setFormData] = useState({
        name: "",
        contactNo: "",
        email: "",
        Subject: "",
        message: "",
    });

    const [loading, setLoading] = useState(false); // Loading state for submission
    const [error, setError] = useState(null); // Error state for submission
    const [successMessage, setSuccessMessage] = useState(null); // Success message for submission

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null); // Reset any previous errors
        setSuccessMessage(null); // Reset success message

        try {
            const response = await axios.post(`${baseurl}/contactus`, formData);
            setSuccessMessage("Your message has been sent successfully!");
            setFormData({
                name: "",
                contactNo: "",
                email: "",
                Subject: "",
                message: "",
            });
        } catch (err) {
            setError("An error occurred while sending the message. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            {/* Section Title */}
            <div className="section-title my-5">
                <div className="line"></div>
                <h2>CONTACT US</h2>
                <div className="line"></div>
            </div>

            <div className="container bg-light rounded p-5 contactPageWrapper">
                <div className="row">
                    {/* Admission Centers */}
                    <div className="col-lg-6">
                        <div className="row mb-2">
                            <h1>Admission Centers</h1>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <h3>British Fort School</h3>
                                        <p>Bhedaghat Main Road Tewar Jabalpur, Contact No :
                                            <a href="tel:8463890002"> 8463890002 </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <h3>British Fort School</h3>
                                        <p>Wright Town Jabalpur, Contact No :
                                            <a href="tel:8461890001"> 8461890001 </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <h3>British Fort School</h3>
                                        <p>Gwarighat Main Road, Near SAM Mall Jabalpur, Contact No :
                                            <a href="tel:9294504099"> 9294504099</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <h3>British Fort School</h3>
                                        <p>Civil Lines Opp GS College Jabalpur, Contact No :<a href="tel:7898945833"> 7898945833 </a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <h3>British Fort School</h3>
                                        <p>Adhartal Near Jain Temple Jabalpur, Contact No :<a href="tel:9755122250"> 9755122250 </a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <div className="d-flex">
                                    <div>
                                        <h3>British Fort School</h3>
                                        <p>Dhanwantri Nagar Chowk Jabalpur, Contact No :<a href="tel:9109122289"> 9109122289</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contact Form */}
                    <div className="col-lg-6 contact-form-wrapper">
                        <div className="border rounded p-3">
                            <h2>Send a message</h2>
                            {error && <div className="alert alert-danger">{error}</div>}
                            {successMessage && <div className="alert alert-success">{successMessage}</div>}
                            <form onSubmit={handleSubmit}>
                                <div className="form-group text-center">
                                    <input
                                        type="text"
                                        placeholder="Your Name"
                                        style={{ width: "100%" }}
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Your Email"
                                        style={{ width: "100%" }}
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        placeholder="Phone"
                                        style={{ width: "100%" }}
                                        name="contactNo"
                                        value={formData.contactNo}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        placeholder="Subject"
                                        style={{ width: "100%" }}
                                        name="Subject"
                                        value={formData.Subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        placeholder="Message"
                                        style={{ width: "100%" }}
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                <button type="submit" className="btn" disabled={loading}>
                                    {loading ? "Sending..." : "Send Message"}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container my-3">
                <div className="row">
                    <div className="col-lg-12">
                        {/* Google Map Embed */}
                        <iframe
                            title="Google Map"
                            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d117388.15719570343!2d79.73432429938441!3d23.15633027606585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3981ae05678c5c79%3A0xe5abac4e6ad7b992!2s12%2C%20Bhedaghat%20Main%20Rd%2C%20Bilha%2C%20Kudan%2C%20Madhya%20Pradesh%20483053!3m2!1d23.1563517!2d79.8167259!5e0!3m2!1sen!2sin!4v1737962448170!5m2!1sen!2sin"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ContactUs;

