
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Layout from '../Component/Layout';
import axios from 'axios';
import './Facilities.css';

const Facilities = () => {
  const [facilityData, setFacilityData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/facilities`);
        if (response.data.success) {
          setFacilityData(response.data.data);
        } else {
          setError('Failed to load facilities data.');
        }
      } catch (err) {
        console.error('Error fetching facilities:', err);
        setError('An error occurred while fetching facilities data.');
      } finally {
        setLoading(false);
      }
    };


    fetchFacilities();
  }, []);

  if (loading) {
    return <div className="loading-message">Loading facilities...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <Layout>
      {/* Dynamic Banner Section */}
      {facilityData.length > 0 && (
        <div className="position-relative text-center mb-5" id="topimg">
          <img
            className="curved-image"
            src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${facilityData[0].FacilitiesBanner}`} // Using first item's banner
            alt="Facilities Banner"

          />
        </div>
      )}

      {/* Dynamic Section Title */}
      <div className="section-title">
        <div className="line"></div>
        <h2>{facilityData.length > 0 ? facilityData[0].Pagetitle : 'Facilities'}</h2>
        <div className="line"></div>
      </div>

      <Container className="mt-5">
        <Row xs={1} md={2} lg={3} className="g-4">
          {facilityData.map((facility) => (
            <Col key={facility.id}>
              <Card className="h-100 d-flex flex-column">
                <div className="img-container" style={{ height: '200px', overflow: 'hidden' }}>
                  <Card.Img
                    variant="top"
                    src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${facility.image}`}
                    alt={facility.title}
                    className="card-image"
                    style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  />
                </div>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{facility.title}</Card.Title>
                  <Card.Text className="flex-grow-1">{facility.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>


    </Layout>
  );
};

export default Facilities;
