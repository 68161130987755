
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import student from "../Gallery/m1.jpg";
import icon from "../Gallery/doubleQuote.png";
import "./Explore.css";

const BenefitsComponent = () => {
  const [benefits, setBenefits] = useState([]);
  const [ourVision, setOurVision] = useState([]);
  const [betterLearning, setBetterLearning] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Fetching data from all the APIs
    const fetchData = async () => {
      try {
        // Fetch benefits
        const benefitsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/benefits`);
        const benefitsData = await benefitsResponse.json();
        setBenefits(benefitsData.data || []);

        // Fetch our vision
        const visionResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/ourvision`);
        const visionData = await visionResponse.json();
        setOurVision(visionData.data || []);

        // Fetch BetterLearning
        const betterLearningResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/BetterLearning`);
        const betterLearningData = await betterLearningResponse.json();
        setBetterLearning(betterLearningData || []);

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // Scroll effect to trigger visibility on scroll
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const benefitsSection = document.getElementById('benefits');

      if (benefitsSection && scrollPosition > benefitsSection.offsetTop) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div className='container learning'>
        <div className='row'>
          <div className='col-md-4 d-flex align-items-center'>
            <div>
              <h3 className='font-weight-bold'>
                A BETTER LEARNING JOURNEY WITH BRITISH FORT FOUNDATION
              </h3>
              <p className='text-white'>
                British Fort Foundation is one of the renowned schools of Jabalpur, very famous for providing top class C.B.S.E. Board education. We at British Fort believe that education should lead the students to a practical, confident and successful living. In our school, we firmly believe that each child is the light of the world. We endeavor to see the budding children as dream kids and the pride achievers in the forthcoming days.
              </p>
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-6 mb-2'> <div className='bgTile rounded py-2 px-3'>3000+ students</div></div>
                  <div className='col-sm-6 mb-2'><div className='bgTile rounded py-2 px-3'>300+ Faculties</div></div>
                  <div className='col-sm-6 mb-2'><div className='bgTile rounded py-2 px-3'>20+ Years</div></div>
                  <div className='col-sm-6 mb-2'><div className='bgTile rounded py-2 px-3'>5000+ Alumni</div></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-8'>
            <div className='student mx-auto my-3 p-1'>
              <img src={student} alt='student' />
            </div>
          </div>
        </div>
      </div>

      <div className='container vision my-2 py-2 rounded' style={{ backgroundColor: 'aliceblue' }}>
        <div className='row gap-5'>
          <div className='col-md-4 py-5'>
            <div className='visionImage text-center mx-auto'>
              <img src={ourVision[0]?.image ? `${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${ourVision[0].image}` : ''} alt='Director' />
              <h5 className='my-3'>" Chairman "</h5>
              <h3>{ourVision[0]?.name || "Anurag Soni"}</h3>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex align-items-center justify-content-center my-auto h-100'>
              <div className='position-relative'>
                <h4 className='p-5 fs-4'>
                  {ourVision.length > 0 ? ourVision[0].description : "Our vision is to be the pre-eminent centre of learning in the country, producing future leaders with a global perspective and an Indian ethos."}
                </h4>
                <img className='position-absolute top-0 left-0' src={icon} alt='icon' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container benefits my-4' id="benefits">
        <div className='row mb-3'>
          <h2 className='text-center'>
            British Fort School Makes Your School Life A Golden Experience
          </h2>
        </div>
        <div className='row benefitsImage'>
          {benefits.length > 0 ? (
            benefits.map((benefit) => (
              <div key={benefit.id} className='col-md-4'>
                <div className='benefitBox position-relative w-100 mb-3'>
                  <div>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL_IMAGE}/upload/${benefit.image}`}
                      alt={benefit.title}
                    />
                  </div>
                  <div className="overlayx">
                    <p className="card-text p-4 position-absolute bottom-0 start-0 text-white">{benefit.title}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No benefits available at the moment.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default BenefitsComponent;
