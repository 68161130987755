
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import "./mainContent.css";

const MainContent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const mainContentSection = document.getElementById('main-content');
      
      if (mainContentSection && scrollPosition > mainContentSection.offsetTop) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
    <Header/>
    <div id="main-content" className={`main-content-container ${isVisible ? 'visible' : ''}`}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={12}>
            <div style={{ textAlign: 'center' }}>
              <h1><span>British Fort </span> Foundation</h1>
            </div>
            <div style={{ fontSize: '16px', lineHeight:"26px" ,fontFamily:'Nunito, sans-serif', fontWeight:"600" }}>
              <p className='text-justify'>British Fort Foundation is one of the renowned schools of Jabalpur, very famous for providing top class C.B.S.E. Board education. We at British Fort believe that education should lead the students to a practical, confident and successful living. In our school, we firmly believe that each child is the light of the world. We endeavor to see the budding children as dream kids and the pride achievers in the forthcoming days.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default MainContent;
