
import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
// import logo from "../Gallery/logo.png";
import logo from "../Gallery/logowhite.png";
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setShowDrawer(false);
        setShowSubmenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleHome = () => {
    setShowDrawer(!showDrawer);
    setShowSubmenu(!showDrawer && !showSubmenu);
  };

  return (
    <nav className="navbar navbar-expand-lg" ref={navbarRef}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
            <div>
              <h6 className='my-0 fw-lighter fst-italic'>' The Most Awarded CBSE School '</h6>
            </div>
          </div>
        </Link>
        <button className="navbar-toggler" type="button" onClick={() => setShowDrawer(!showDrawer)}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${showDrawer ? 'show' : ''}`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink exact className="nav-link text-center" to="/" onClick={handleHome}>Home</NavLink>
              {showSubmenu && (
                <ul className="submenu">
                  <li><NavLink to="/LifeStyle" className="nav-link" activeClassName="active">Lifestyle</NavLink></li>
                  <li><NavLink to="/KinderGarten" className="nav-link" activeClassName="active">Kindergarten</NavLink></li>
                  <li><NavLink to="/ImageGallery" className="nav-link" activeClassName="active">Image Gallery</NavLink></li>
                  <li><NavLink to="/NewUpdate" className="nav-link" activeClassName="active">New Update</NavLink></li>
                  <li><NavLink to="/Download" className="nav-link" activeClassName="active">Download</NavLink></li>
                </ul>
              )}
            </li>
            <li className="nav-item text-center">
              <NavLink exact className="nav-link" to="/Aboutus" activeClassName="active">About us</NavLink>
            </li>
            <li className="nav-item text-center">
              <NavLink exact className="nav-link" to="/Facilities" activeClassName="active">Facilities</NavLink>
            </li>
            <li className="nav-item text-center">
              <NavLink exact className="nav-link" to="/Activities" activeClassName="active">Sports Facilities</NavLink>
            </li>
            <li className="nav-item text-center">
              <NavLink exact className="nav-link" to="/ContactUs" activeClassName="active">Contact us</NavLink>
            </li>
            <li className="nav-item text-center">
              <NavLink exact className="nav-link" to="/RegistrationForm" activeClassName="active">Registration</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
